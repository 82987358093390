import React from "react";

const Badges = () => {
  return (
    <div
      style={{
        display: "flex",
        // justifyContent: "center",
        alignItems: "center",
        paddingTop: "2rem",
        gap: "1rem",
      }}
    >
      <img src={"/images/google.png"} alt="badge" className="badge" />
      <img src={"/images/2-year-warranty.png"} alt="badge" className="badge" />
    </div>
  );
};

export default Badges;
