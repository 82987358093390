import React from "react";
import { Link } from "react-router-dom";

const Button = ({ data, className }) => {
  if (data?.link?.[0] === "/")
    return (
      <Link to={data?.link} className={className}>
        {data?.text}
      </Link>
    );
  return (
    <a className={className} href={data?.link} target="_blank">
      {data?.text}
    </a>
  );
};

export default Button;
