import React from "react";

import Badges from "./Badges";
import CalendlyButtons from "../CalendlyButtons";

const images = [
  //
  "/images/AdobeStock_655724280.jpeg",
  "/images/AdobeStock_847253969.jpeg",
  "/images/AdobeStock_889802359.jpeg",
  "/images/AdobeStock_922006548.jpeg",
  "/images/AdobeStock_1017442101.jpeg",
];

const image = images[3];

const HeroCTA = () => {
  return (
    <div
      className="heroCTA"
      style={{
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.2) 100%), url(${image})`,
        minHeight: "120vh",
      }}
    >
      <div style={{ maxWidth: 800, margin: "0 auto" }}>
        <h1>Best Countertops in Niagara</h1>
        <h2>Transforming Homes Since 1985</h2>
        <div>
          <ListItem a={"Free In-Home Consultation"} b={"Tailored advice from our design experts, right at your doorstep."} />
          <ListItem a={"Instant, No-Obligation Quotes"} b={"Transparent pricing with no surprises."} />
          <ListItem a={"Hassle-Free Installation"} b={"Professional service to bring your vision to life."} />
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 15,
          }}
        >
          <CalendlyButtons mode={2} />

          <a
            style={{
              border: "2px solid var(--orange)",
              color: "var(--orange)",
              padding: "1rem 2rem",
              borderRadius: "10px",
              fontSize: "1.4rem",
              boxShadow: "0px 10px 10px rgba(0,0,0,0.2)",
              textDecoration: "none",
              backgroundColor: "rgba(0, 0, 0, 0.3)",

              // textShadow: "none",
            }}
            href="tel:9057141699"
          >
            (905) 714-1699
          </a>
        </div>
        <Badges />
      </div>
    </div>
  );
};

export default HeroCTA;

const ListItem = ({ a, b }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "10px",
        marginLeft: "1rem",
        marginBottom: "1rem",
      }}
    >
      <img src="/images/check.png" style={{ width: 30, height: 30 }} />
      <div>
        <p
          style={{
            margin: 0,
            fontSize: "1.2rem",
            fontWeight: "bold",
            color: "var(--white)",
          }}
        >
          {a}
        </p>

        <p
          style={{
            margin: 0,
            fontSize: "1rem",
            color: "var(--white)",
          }}
        >
          {b}
        </p>
      </div>
    </div>
  );
};
