import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { v4 as uuid } from "uuid";
const Maps = ({ data, selectedItem, onChange, autoplay }) => {
  return (
    <div className="slider w-slider">
      <div className="w-slider-mask">
        <Carousel
          selectedItem={selectedItem}
          onChange={onChange}
          showThumbs={false}
          autoPlay={autoplay === undefined || autoplay ? true : false}
          interval={3000}
          infiniteLoop={true}
          renderArrowPrev={(clickHandler, hasPrev, label) => {
            if (data?.length <= 1) return null;
            return (
              <div className="arrowspacing w-slider-arrow-left" onClick={clickHandler}>
                <div className="icon-2 w-icon-slider-left" />
                <div className="icon-2 leftdot w-icon-slider-right" />
              </div>
            );
          }}
          renderArrowNext={(clickHandler, hasNext, label) => {
            if (data?.length <= 1) return null;
            return (
              <div className="arrowspacing w-slider-arrow-right" onClick={clickHandler}>
                <div className="icon-2 rightdot w-icon-slider-right" />
                <div className="icon-2 w-icon-slider-right" />
              </div>
            );
          }}
          renderIndicator={(clickHandler, isSelected, index, label) => {
            if (data?.length <= 1) return null;
            return <div onClick={clickHandler} className={`sliderIndicator ${isSelected && "activeIndicator"}`}></div>;
          }}
          showStatus={false}
          stopOnHover={true}
          animationHandler="fade"
        >
          {data?.map((el, i) => {
            let item = el?.location?.data?.attributes;
            return (
              <div key={uuid()} className="locationslidecont w-slide">
                <div className="locationslide">
                  <div className="locationslidemap">
                    <iframe
                      loading="lazy"
                      allowFullScreen
                      referrerPolicy="no-referrer-when-downgrade"
                      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_MAPS_API}
    &q=${item?.mapUrl}`}
                    />
                  </div>

                  <div className="div-block-4">
                    <div className="div-block-6 shadow" style={{ width: "100%" }}>
                      <div className="hourgridaddy">
                        {item?.address}
                        <br />
                      </div>
                      <div className="hourgridtown">
                        {item?.city}
                        <br />
                      </div>
                      <a href={`tel:+${item?.phone?.replace(/\s+/g, "")}`} className="hourgridphone">
                        {item?.phone}
                      </a>
                      {item?.hours?.map((el) => {
                        return (
                          <div key={uuid()} className="hourgridrow">
                            <div className="hourgridday">{el.day}</div>
                            <div>{el.time}</div>
                          </div>
                        );
                      })}
                      {item?.logo?.data?.attributes && (
                        <div className="div-block-12">
                          <img src={item?.logo?.data?.attributes?.url} loading="lazy" alt="" className="maplogo" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Maps;
