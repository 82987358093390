import React, { useRef } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";

const Reviews = () => {
  const slideRef = useRef(null);
  return (
    <div style={{ backgroundColor: "var(--orange)", padding: "3rem", paddingTop: "3rem" }}>
      <div
        style={{
          maxWidth: 1200,
          margin: "0 auto",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            flex: 1,
            paddingTop: "2rem",
            minWidth: "300px",
          }}
        >
          <h2 style={{ color: "var(--white)", margin: 0, marginBottom: "1rem", fontSize: "2rem" }}>Your satisfaction has been our priority since day one.</h2>
        </div>
        <div
          style={{
            flex: 3,
            width: 0,
            minWidth: "300px",
            position: "relative",
          }}
        >
          <Splide
            ref={slideRef}
            options={{
              // Desktop on down
              perPage: 3,
              perMove: 1,
              autoplay: false,
              // interval: 2000,
              speed: 2000, // transition speed in miliseconds
              pauseOnHover: true,
              focus: "center", // 0 = left and 'center' = center
              type: "loop", // 'loop' or 'slide'
              gap: "1rem", // space between slides
              arrows: false,
              pagination: "false", // 'slider' or false
              dragAngleThreshold: 30, // default is 30
              autoWidth: false, // for cards with differing widths
              rewind: false, // go back to beginning when reach end
              rewindSpeed: 0,
              waitForTransition: false,
              updateOnMove: true,
              trimSpace: true, // true removes empty space from end of list
              breakpoints: {
                991: {
                  // Tablet
                  perPage: 3,
                },
                767: {
                  // Mobile Landscape
                  perPage: 2,
                },
                479: {
                  // Mobile Portrait
                  perPage: 1,
                },
              },
            }}
          >
            {reviews.map((review) => {
              return (
                <SplideSlide key={review.name}>
                  <ReviewCard {...review} />
                </SplideSlide>
              );
            })}
          </Splide>
          <button
            onClick={() => slideRef.current.splide.go(">")}
            style={{
              position: "absolute",
              right: "-20px",
              top: "50%",
              transform: "translateY(-50%)",
              background: "var(--orange)",
              border: "none",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              fontSize: "20px",
              cursor: "pointer",
            }}
          >
            <img src={"/images/chevron.png"} alt="arrow" style={{ width: "1.2rem", height: "1.2rem" }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Reviews;

const ReviewCard = ({ name, review }) => {
  const length = 500;
  const truncatedReview = review.length > length ? review.slice(0, length) + "..." : review;

  return (
    <div
      style={{
        backgroundColor: "var(--white",
        padding: "1rem",
        borderRadius: "8px",
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
        // height: "100%",
      }}
    >
      <div style={{ display: "flex", marginBottom: "1.5rem" }}>
        <img src={"/images/star.png"} alt="star" style={{ width: "1.2rem", height: "1.2rem" }} />
        <img src={"/images/star.png"} alt="star" style={{ width: "1.2rem", height: "1.2rem" }} />
        <img src={"/images/star.png"} alt="star" style={{ width: "1.2rem", height: "1.2rem" }} />
        <img src={"/images/star.png"} alt="star" style={{ width: "1.2rem", height: "1.2rem" }} />
        <img src={"/images/star.png"} alt="star" style={{ width: "1.2rem", height: "1.2rem" }} />
      </div>
      <p>{truncatedReview}</p>
      <p style={{ fontSize: "0.8rem", fontWeight: "bold" }}>- {name}</p>
    </div>
  );
};

const reviews = [
  {
    name: "Lijesh Pj",
    review:
      "We have recently changed our counter top in kitchen and bathroom. Dave did an absolutely wonderful job from top to bottom. Really nice customer care. More than that, a very important thing is that Dave is a trustworthy person; he will keep up his promises. 💯 We strongly recommend him for your services.",
  },
  {
    name: "Peggy Palliardi",
    review:
      "What a wonderful experience! The whole process went super fast, from Michelle showing us all available options to Peter and his team measuring, cutting, and installing the quartz slab we selected. It only took two weeks! We LOVE our new countertop! So much craftsmanship going into the careful placement of the seam so it's barely visible, integrating and installing our new sink, it just looks spectacular! Thank you so much, everybody!",
  },
  {
    name: "Tara Belanger",
    review: "Very professional, with great workmanship. They worked fast and efficiently. We are very pleased with the outcome. We would definitely recommend this company!",
  },
  {
    name: "Chad Belcher",
    review:
      "OMG, such an awesome group to deal with. We had to replace a countertop that was damaged (by another provider) by their installation. Michelle (and another lady, I forget her name) in the showroom was amazingly helpful in helping us decide what to replace it with. We took multiple samples home on several occasions and had several email communications. The templating by Dave was extremely thorough and absolutely precise. On the installation day, Sven, Peter, and the rest of the 'muscle' did an amazing job to remove a rather poorly installed countertop and gave us the counter we'd always wanted. They stayed until it completely met our expectations and were very attentive to all of our questions and concerns - having had a very bad experience with the other installer. I can't recommend Niagara Countertops enough - we'll definitely use them again in the future if/when the need arises.",
  },
  {
    name: "S Tritchew",
    review:
      "Great service from start to finish! Irene returned my initial call promptly. Michelle walked me through everything at the store and was patient and responsive to all my questions and changes along the way. Dave and Spencer did a phenomenal job with the counter install! The Quartz counter looks amazing and transformed my kitchen! I highly recommend this business and plan on using them again for my bathroom counters! Thanks to everyone there for a wonderful experience... Stress-free!",
  },
];
