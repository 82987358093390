import React from "react";

const Steps = () => {
  const steps = [
    {
      number: 1,
      title: ["Let’s", "Chat!"],
      description: "Kick things off with a friendly consultation—at home or in-store!",
    },
    {
      number: 2,
      title: ["Design", "& Delight"],
      description: "Together, we’ll create a stylish design that fits your vibe and budget.",
    },
    {
      number: 3,
      title: ["Installation", "Magic"],
      description: "Our pros will handle the installation to bring your vision to life.",
    },
    {
      number: 4,
      title: ["Enjoy the", "Wow Factor!"],
      description: "Sit back, relax, and enjoy your stunning new space!",
    },
  ];

  return (
    <div
      style={{
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "40px 20px",
      }}
      className="steps"
    >
      <h2
        style={{
          textAlign: "center",
          marginBottom: "40px",
        }}
      >
        Your Easy Steps
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {steps.map((step) => (
          <div
            key={step.number}
            style={{
              flex: 1,
              minWidth: "150px",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                backgroundColor: "var(--orange)",
                color: "white",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              {step.number}
            </div>
            <h3>
              {step.title.map((el) => {
                return (
                  <>
                    {el}
                    <br />
                  </>
                );
              })}
            </h3>
            <p
              style={{
                fontSize: "1rem",
                color: "#666",
              }}
            >
              {step.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Steps;
