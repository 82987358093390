// This function takes any webflow form and sends the data to strapi.
// using the EZ Form plugin, strapi saves this data and emails it out
// to notification recipients using the sendgrid API.
// It also displays our success or error banner components

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../util/BACKEND_URL";

export const useSubmitForm = (formName) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (loading) return;

    setLoading(true);

    let form = {
      formName,
    };
    for (let i = 0; i < e.target.length; i++) {
      if (e.target[i].type !== "submit") {
        form[e.target[i].name] = e.target[i].value;
      }
    }

    axios
      .post(`${BACKEND_URL}/api/ezforms/submit`, { formData: form })
      .then(() => {
        navigate(window.location.pathname + "?status=success");
        setSubmitted(true);
        setLoading(false);
      })
      .catch((error) => {
        navigate(window.location.pathname + "?status=error");
        setLoading(false);
      });
  };

  // return those functions
  return {
    handleSubmit,
    loading,
    submitted,
  };
};
