import React from "react";
import ProductSlider from "./ProductSlider";

const ProductRow = ({ data, i }) => {
  return (
    <div className={`productrow ${i % 2 !== 0 && "odd"}`}>
      <div className="servicetextdiv">
        <div className="servicetext orange">{data?.title}</div>
        <div className="div-block-7">
          <div className="bluedot small" />
          <div className="bluedot" />
          <div className="bluedot small" />
        </div>
      </div>
      <ProductSlider data={data?.images?.data} />
    </div>
  );
};

export default ProductRow;
