import React from "react";

const Table = ({ data = {}, isSubPage = false }) => {
  if (!data.columns) return null;

  const renderItemContent = (item) => {
    let check = isSubPage ? "../images/check.png" : "images/check.png";
    let x = isSubPage ? "../images/tablex.png" : "images/tablex.png";
    if (item.icon === "check") return <img src={check} loading="lazy" alt="item has this feature" className="tableicon" />;
    if (item.icon === "x") return <img src={x} loading="lazy" alt="item does not have this feature" className="tableicon" />;

    let fontSize = item.content.length > 20 ? "small" : "large";
    return (
      <div className={`tablecellcontent ${fontSize}`}>
        {item.content.split("\n").map((el) => (
          <p>{el}</p>
        ))}
      </div>
    );
  };

  return (
    <>
      {data.Heading && <h2 className="table-heading">{data.Heading}</h2>}
      <div className="table">
        {data.columns?.map((col, i) => {
          return (
            <>
              {/* main column */}
              <div style={{ background: col.colorHexCode }} className={`col ${i === 0 && "_1"} `}>
                {/* main items */}
                {col.Items?.map((item, j) => {
                  // first item of column
                  if (j === 0) {
                    return col.link ? (
                      <a className="head" href={col.link}>
                        <div className="tablecolheading">{item.content}</div>
                      </a>
                    ) : (
                      <div className="head">
                        <div className="tablecolheading">{item.content}</div>
                      </div>
                    );
                  }

                  // first column
                  if (i === 0)
                    return (
                      <div className={`row _1 ${j % 2 === 1 ? "dark" : ""}`}>
                        <div className="tablerowlabel">{item.content}</div>
                      </div>
                    );

                  // regular table items
                  return <div className={`row opacity ${j % 2 === 1 ? "dark" : ""}`}>{renderItemContent(item)}</div>;
                })}

                <div className="foot"></div>
              </div>

              {/* skinny column */}
              {i !== data.columns.length - 1 && (
                <div className="col skinny">
                  {/* skinny items */}
                  {col.Items?.map((item, i) => {
                    if (i === 0) return <div className="head"></div>;
                    return <div className={`row ${i % 2 === 1 ? "dark" : ""}`}></div>;
                  })}
                </div>
              )}
            </>
          );
        })}
      </div>
    </>
  );
};

export default Table;
