// This file defines the strapi endpoints you want to pull dtata from (collections & single types)
// This data will be fetched every time the page is loaded or refreshed

// slug = database table name, also known as API ID (Plural)
// fields = what columns from the table do you want to use?
// populate = how to show nested components & relations (doc: https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/populating-fields.html#relation-media-fields)
// There's two formats for doing this. Populate can be a string or qs.stringify()

import qs from "qs";

export const strapiPublicEndpoints = [
  // Single Types
  {
    slug: "navbar",
    // populate: "populate=*",
    populate: qs.stringify({
      populate: {
        logo: {
          populate: "*",
        },
        navLinks: {
          populate: {
            subLinks: {
              populate: "*",
            },
          },
        },
      },
    }),
  },
  {
    slug: "footer",
    populate: qs.stringify({
      populate: {
        socials: {
          populate: {
            image: {
              populate: "*",
            },
          },
        },
        logo: {
          populate: "*",
        },
        preFooterButton: {
          populate: "*",
        },
        cta: {
          populate: "*",
        },
        hours: {
          populate: "*",
        },
      },
    }),
  },
  {
    slug: "landing-page",
    populate: qs.stringify({
      populate: {
        socials: {
          populate: {
            image: {
              populate: "*",
            },
          },
        },
        awards: {
          populate: "*",
        },
        buttons: {
          populate: "*",
        },

        heroBackground: {
          populate: "*",
        },
        processImg: {
          populate: "*",
        },
        postMapsButton: {
          populate: "*",
        },
        textBlock1Button: {
          populate: "*",
        },
        textBlock2Button: {
          populate: "*",
        },
        textBlock3Button: {
          populate: "*",
        },
        locations: {
          populate: {
            location: {
              populate: {
                logo: {
                  populate: "*",
                },
                hours: {
                  populate: "*",
                },
              },
            },
          },
        },
        table: {
          populate: {
            table: {
              populate: {
                columns: {
                  populate: "*",
                },
              },
            },
          },
        },
        logo: {
          populate: "*",
        },
        products: {
          populate: {
            product: {
              populate: {
                images: "*",
              },
            },
          },
        },
      },
    }),
  },
  {
    slug: "products-page",
    populate: qs.stringify({
      populate: {
        products: {
          populate: {
            product: {
              populate: {
                images: "*",
                suppliers: {
                  populate: "*",
                },
                table: {
                  populate: {
                    table: {
                      populate: {
                        columns: {
                          populate: "*",
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        logo: {
          populate: "*",
        },
        heroBackground: {
          populate: "*",
        },
        featuresImg: {
          populate: "*",
        },
        table: {
          populate: {
            table: {
              populate: {
                columns: {
                  populate: "*",
                },
              },
            },
          },
        },
      },
    }),
  },
  {
    slug: "mobile-showroom-page",
    populate: qs.stringify({
      populate: {
        logo: {
          populate: "*",
        },
        heroBackground: {
          populate: "*",
        },
        locations: {
          populate: {
            location: {
              populate: {
                logo: {
                  populate: "*",
                },
                hours: {
                  populate: "*",
                },
              },
            },
          },
        },
      },
    }),
  },

  {
    slug: "contact-page",
    populate: qs.stringify({
      populate: {
        logo: {
          populate: "*",
        },
        heroBackground: {
          populate: "*",
        },
        locations: {
          populate: {
            location: {
              populate: {
                logo: {
                  populate: "*",
                },
                hours: {
                  populate: "*",
                },
              },
            },
          },
        },
      },
    }),
  },
  {
    slug: "pop-up",
    populate: "*",
  },
  {
    slug: "dealers-page",
    populate: qs.stringify({
      populate: {
        logo: {
          populate: "*",
        },
        heroBackground: {
          populate: "*",
        },
        dealers: {
          populate: {
            location: {
              populate: {
                logo: {
                  populate: "*",
                },
                hours: {
                  populate: "*",
                },
              },
            },
          },
        },
      },
    }),
  },
  {
    slug: "seo",
    populate: qs.stringify({
      populate: {
        page: {
          populate: "*",
        },
      },
    }),
  },

  {
    slug: "marketing-page-2",
    populate: qs.stringify({
      populate: {
        products: {
          populate: {
            product: {
              populate: {
                images: "*",
                suppliers: {
                  populate: "*",
                },
              },
            },
          },
        },
      },
    }),
  },
  {
    slug: "marketing-page-1",
    populate: qs.stringify({
      populate: {
        products: {
          populate: {
            product: {
              populate: {
                images: "*",
                suppliers: {
                  populate: "*",
                },
                // table: {
                //   populate: {
                //     table: {
                //       populate: {
                //         columns: {
                //           populate: "*",
                //         },
                //       },
                //     },
                //   },
                // },
              },
            },
          },
        },
      },
    }),
  },
];

// An example for getting complicated with the populate feild:
//   populate: qs.stringify({
//     populate: {
//       exampleSlider: {
//         populate: {
//           items: {
//             populate: "background",
//           },
//         },
//       },
//       exampleAccordion: {
//         populate: ["items"],
//       },
//     },
//   }),
