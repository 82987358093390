import React, { useState } from "react";
import { useSelector } from "react-redux";
import NavSwitch from "../components/NavSwitch";
import Page from "../components/Page";

// your page that has the form must include this import line.
// This is a function that submits whatever data is in your form to strapi,
// where it is stored in the database and emailed out to all notification recipients
import { useSubmitForm } from "../hooks/useSubmitForm";
import LoadingPage from "./LoadingPage";
import { v4 as uuid } from "uuid";
import RichText from "../components/RichText";

// Each input in your form must have a unique name attribute e.x. name="Email"
// your form tag must have the onSubmit={useSubmitForm} attribute
function MobileShowroomPage() {
  const [method, setMethod] = useState();

  const { handleSubmit, loading, submitted } = useSubmitForm("mobile-showroom");
  // access our data so that we can display it
  const singleTypeSlug = "mobile-showroom-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;

  return (
    <Page data-wf-page="63a4b19216e186453118707a" data-wf-site="63a4ab5fc97e6aeff12033c5">
      <div
        className="hero-subpage wf-section"
        style={{
          backgroundPosition: "0px 0px, 50% 50%",
          backgroundImage: `radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, 0.6), transparent), url('${cmsData?.heroBackground?.data?.attributes?.url}')`,
        }}
      >
        <div className="container-2 w-container">
          <img src={cmsData?.logo?.data?.attributes?.url} loading="lazy" alt="" className="mainlogo subpage" />
          {cmsData?.tagline?.split("\n")?.map((el) => {
            return (
              <div key={uuid()} className="tagline">
                <strong className="taglinetext">{el}</strong>
              </div>
            );
          })}
        </div>
      </div>

      <NavSwitch />
      <div className="textblock subpage wf-section">
        <div className="w-container">
          <div className="narrow-textblock">
            <RichText text={cmsData?.textBlock1} />
          </div>
        </div>
      </div>
      <div className="section-9 wf-section" style={{ marginBottom: "40px" }}>
        <div className="w-container">
          <div className="w-form">
            {/* Name
Address (City, Street, Postal Code)
Preferred method of contact (they choose one of and appropriate field opens)
Phone
Email
Text */}
            <form onSubmit={handleSubmit} id="email-form" name="email-form" className="form">
              <input type="text" className="input w-input" maxLength={256} name="Name" data-name="Name" placeholder={cmsData?.namePlaceholder} id="Name" />
              <input type="text" className="input _w-40 w-input" maxLength={256} name="Address" data-name="Address" placeholder={cmsData?.addressPlaceholder} id="Address" />
              <input type="text" className="input _w-40 w-input" maxLength={256} name="City" data-name="City" placeholder={cmsData?.cityPlaceholder} id="City" />
              <input type="text" className="input _w-20 w-input" maxLength={256} name="PostalCode" data-name="Postal Code" placeholder={cmsData?.postalCodePlaceholder} id="PostalCode" />

              <select
                value={method}
                onChange={(e) => setMethod(e.target.value)}
                id="PreferredMethodOfContact"
                name="PreferredMethodOfContact"
                data-name="Preferred Method Of Contact"
                className={`input w-select ${method ? "_w-40" : "_w-100"}`}
              >
                <option value="">Prefered Method of Contact...</option>
                <option value="Email">{cmsData?.emailPlaceholder}</option>
                <option value="Phone">{cmsData?.phonePlaceholder}</option>
                <option value="Text">{cmsData?.textPlaceholder}</option>
              </select>

              {method === "Email" && <input type="email" className="input _w-60 w-input" maxLength={256} name="Email" data-name="Email" placeholder={cmsData?.emailPlaceholder} id="Email" />}
              {(method === "Phone" || method === "Text") && (
                <input type="text" className="input _w-60 w-input" maxLength={256} name="Phone" data-name="Phone" placeholder={cmsData?.phonePlaceholder} id="Phone" />
              )}
              <textarea placeholder={cmsData?.messagePlaceholder} maxLength={5000} id="Message" name="Message" data-name="Message" className="input multiline w-input" defaultValue={""} />
              <div className="div-block-8">{!submitted && <input type="submit" value={!loading ? cmsData?.submitButtonText : "Loading..."} className="button cotnact shadow w-button" />}</div>
            </form>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default MobileShowroomPage;
