// Allows you to display rich text markdown coming from strapis collections
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "../css/RichText.css";

function RichText({ text, left = true }) {
  if (!text) return null;

  return (
    <div style={{ textAlign: left ? "left" : "center", fontSize: 16 }}>
      <ReactMarkdown children={text} remarkPlugins={[remarkGfm]} />
    </div>
  );
}

export default RichText;
