import { Splide, SplideSlide } from "@splidejs/react-splide";
import React from "react";
import { v4 as uuid } from "uuid";

const ProudPartners = ({ suppliers }) => {
  return (
    <div style={{ textAlign: "center", padding: "3rem" }}>
      <h2>Proud Partners</h2>
      <div className="div-block-11">
        <Splide
          options={{
            // Desktop on down
            perPage: 4,
            perMove: 1,
            autoplay: true,
            interval: 2000,
            speed: 2000, // transition speed in miliseconds
            pauseOnHover: true,
            focus: "center", // 0 = left and 'center' = center
            type: "loop", // 'loop' or 'slide'
            gap: "4.6875em", // space between slides
            arrows: "false", // 'slider' or false
            pagination: "false", // 'slider' or false
            dragAngleThreshold: 30, // default is 30
            autoWidth: false, // for cards with differing widths
            rewind: false, // go back to beginning when reach end
            rewindSpeed: 0,
            waitForTransition: false,
            updateOnMove: true,
            trimSpace: false, // true removes empty space from end of list
            breakpoints: {
              991: {
                // Tablet
                perPage: 4,
              },
              767: {
                // Mobile Landscape
                perPage: 3,
              },
              479: {
                // Mobile Portrait
                perPage: 2,
              },
            },
          }}
        >
          {suppliers.map((el) => {
            return (
              <SplideSlide key={uuid()}>
                <img src={el.image.data.attributes.url} alt="Image 1" />
              </SplideSlide>
            );
          })}
        </Splide>

        <div className="gradient-cover"></div>
      </div>
    </div>
  );
};

export default ProudPartners;
