export const WAYPOINT = "WAYPOINT";

export const waypoint = (direction) => {
  return async (dispatch) => {
    dispatch({
      type: WAYPOINT,
      direction: direction,
    });
  };
};
