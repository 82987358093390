import React from "react";

const LoadingPage = ({ isSubPage = false }) => {
  let image = "./images/NCT-2022-Logo---Icon-Lines.png";
  if (isSubPage) image = "./../images/NCT-2022-Logo---Icon-Lines.png";

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh" }}>
      <img src={image} />
      <p>Loading...</p>
    </div>
  );
};

export default LoadingPage;
