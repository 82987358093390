import React from "react";
import { Waypoint } from "react-waypoint";
import * as navActions from "../store/actions/nav";
import { useDispatch } from "react-redux";

const NavSwitch = () => {
  const dispatch = useDispatch();
  return <Waypoint onEnter={() => dispatch(navActions.waypoint("up"))} onLeave={() => dispatch(navActions.waypoint("down"))} />;
};

export default NavSwitch;
