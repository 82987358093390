import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import NavSwitch from "../components/NavSwitch";
import Page from "../components/Page";
import LoadingPage from "./LoadingPage";
import { v4 as uuid } from "uuid";
import RichText from "../components/RichText";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import Table from "../components/Table";
import { useParams } from "react-router-dom";

const ProductDetailPage = () => {
  // access our data so that we can display it
  const singleTypeSlug = "products-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  //  pull id from the url
  const { id } = useParams();

  let item = cmsData?.products?.find((el) => el.product.data.attributes.slug === id)?.product?.data?.attributes;

  console.log("item", item);
  // only display the component if our data has been loaded
  if (!cmsData || !item) return <LoadingPage isSubPage />;

  return (
    <Page data-wf-page="63a4aec8c97e6a6285206a0e" data-wf-site="63a4ab5fc97e6aeff12033c5">
      <div
        className="hero-subpage products wf-section"
        style={{
          backgroundPosition: "0px 0px, 50% 50%",
          backgroundImage: `radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, 0.6), transparent), url('${cmsData?.heroBackground?.data?.attributes?.url}')`,
        }}
      >
        <div className="container-2 w-container">
          <img src={cmsData?.logo?.data?.attributes?.url} loading="lazy" alt="" className="mainlogo subpage" />
          {cmsData?.tagline?.split("\n")?.map((el) => {
            return (
              <div key={uuid()} className="tagline">
                <strong className="taglinetext">{el}</strong>
              </div>
            );
          })}
        </div>
      </div>
      <NavSwitch />

      <div key={uuid()} id={item.title} className="product-section wf-section">
        <div className="w-container">
          <h2 className="heading2-subtle orange">{item.title}</h2>
          <div className={`producttextslidercont odd`}>
            <div className={`text-block  odd}`}>
              <RichText text={item.description} />
            </div>
            {/* slider */}
            <div className="slider-4" style={{ height: 300 }}>
              <Carousel showThumbs={false} autoPlay={true} interval={4000} infiniteLoop={true} showStatus={false} stopOnHover={true} animationHandler="fade">
                {item?.images?.data?.map((el, i) => {
                  return <img key={uuid()} src={el.attributes.url} style={{ height: 300, objectFit: "cover" }} />;
                })}
              </Carousel>
            </div>
          </div>
          {/* splide */}
          {item.suppliers.length < 3 ? (
            <div className="div-block-13">
              {item.suppliers.map((el) => {
                return (
                  <div key={uuid()} className="splide__slide few">
                    <img src={el.image.data.attributes.url} loading="lazy" alt="" />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="div-block-11">
              <Splide
                options={{
                  // Desktop on down
                  perPage: 4,
                  perMove: 1,
                  autoplay: true,
                  interval: 2000,
                  speed: 2000, // transition speed in miliseconds
                  pauseOnHover: true,
                  focus: "center", // 0 = left and 'center' = center
                  type: "loop", // 'loop' or 'slide'
                  gap: "4.6875em", // space between slides
                  arrows: "false", // 'slider' or false
                  pagination: "false", // 'slider' or false
                  dragAngleThreshold: 30, // default is 30
                  autoWidth: false, // for cards with differing widths
                  rewind: false, // go back to beginning when reach end
                  rewindSpeed: 0,
                  waitForTransition: false,
                  updateOnMove: true,
                  trimSpace: false, // true removes empty space from end of list
                  breakpoints: {
                    991: {
                      // Tablet
                      perPage: 4,
                    },
                    767: {
                      // Mobile Landscape
                      perPage: 3,
                    },
                    479: {
                      // Mobile Portrait
                      perPage: 2,
                    },
                  },
                }}
              >
                {item.suppliers.map((el) => {
                  return (
                    <SplideSlide key={uuid()}>
                      <img src={el.image.data.attributes.url} alt="Image 1" />
                    </SplideSlide>
                  );
                })}
              </Splide>

              <div className="gradient-cover"></div>
            </div>
          )}
        </div>
        <div
          style={{
            width: "90vw",
            margin: "0 auto",
          }}
        >
          <Table isSubPage data={item?.table?.table?.data?.attributes} />
        </div>
      </div>
    </Page>
  );
};

export default ProductDetailPage;
