import React, { useEffect } from "react";
import { useSubmitForm } from "../../hooks/useSubmitForm";

function Form() {
  const { handleSubmit, loading, submitted } = useSubmitForm("landing-1-form");

  useEffect(() => {
    // refresh the page
    if (submitted) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [submitted]);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        // Trigger Google Ads conversion tracking
        if (window.gtag) {
          window.gtag("event", "conversion", {
            send_to: "AW-16756825034/VvYaCL6jzeYZEMq3o7Y-",
          });
        }
        handleSubmit(e);
      }}
      id="email-form"
      name="email-form"
      className="form landing-1-form"
    >
      <input type="text" className="input _w-50  w-input" maxLength={256} name="Name" data-name="Name" placeholder="Your Name *" id="Name" required />
      <input type="email" className="input _w-50 w-input" maxLength={256} name="Email" data-name="Email" placeholder="Your Email *" id="Email" required />
      <input type="text" className="input _w-50 w-input" maxLength={256} name="Phone" data-name="Phone" placeholder="Your Phone Number *" id="Phone" required />
      <input type="text" className="input _w-50  w-input" maxLength={256} name="City" data-name="City" placeholder="Your City" id="City" />

      <div className="radio-group">
        <label className="radio-main-label">Select Service Type</label>
        <div className="radio-option">
          <input type="radio" id="kitchen-cabinets" name="ServiceType" value="Kitchen Cabinets & Vanities" required />
          <label htmlFor="kitchen-cabinets">Kitchen Cabinets & Vanities</label>
        </div>
        <div className="radio-option">
          <input type="radio" id="countertops" name="ServiceType" value="Countertops" />
          <label htmlFor="countertops">Countertops</label>
        </div>
        <div className="radio-option">
          <input type="radio" id="whole-kitchens" name="ServiceType" value="Whole Kitchens" />
          <label htmlFor="whole-kitchens">Whole Kitchens</label>
        </div>
        <div className="radio-option">
          <input type="radio" id="other" name="ServiceType" value="Other" />
          <label htmlFor="other">Other</label>
        </div>
      </div>
      <textarea placeholder="Additional Information" maxLength={5000} id="AdditionalInfo" name="AdditionalInfo" data-name="AdditionalInfo" className="input multiline w-input" defaultValue={""} />

      <div className="div-block-8">
        <input type="submit" value={!loading ? "Get a Free Quote" : "Loading..."} className="button cotnact shadow w-button submit-btn" />
      </div>
    </form>
  );
}

export default Form;
