// This banner display displays whether or not a form was submitted successfully.
// Its pretty generic and likely wont need to be modified.
// If you want to change the way it looks or what it says you can do so below
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Success settings
let success = {
  message: "Thanks for your message! We'll get back to you as soon as we can",
  backgroundColor: "#4BB543",
  textColor: "white",
};

// General Banner CSS Styles
let cssStyles = {
  display: "flex",
  position: "absolute",
  top: 30,
  left: 0,
  width: "80%",
  marginLeft: "10%",
  marginRight: "10%",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  padding: 20,
  borderRadius: 8,
  fontSize: 22,
  zIndex: 999,
};

function Banner() {
  const [display, setDisplay] = useState(false);
  const [content, setContent] = useState("");
  const [background, setBackground] = useState("white");
  const [color, setColor] = useState("black");
  const navigate = useNavigate();
  let params = new URL(document.location).searchParams;
  let status = params.get("status");

  // check for url params
  useEffect(() => {
    if (status === "success") {
      setDisplay(true);
      setContent(success.message);
      setBackground(success.backgroundColor);
      setColor(success.textColor);
      setTimeout(() => {
        setDisplay(false);
        navigate(window.location.pathname);
      }, 2000);
    }
  }, [status]);

  return (
    <div
      style={
        display
          ? {
              ...cssStyles,
              backgroundColor: background,
              color: color,
            }
          : { display: "none" }
      }
    >
      {content}
    </div>
  );
}

export default Banner;
