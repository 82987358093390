import React, { useEffect } from "react";
import WebFont from "webfontloader";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

function Head() {
  // access our data so that we can display it
  const singleTypeSlug = "seo";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  const { id } = useParams();

  useEffect(() => {
    WebFont.load({ google: { families: ["Jost:regular,800,italic"] } });
  }, []);

  let seo = cmsData?.page?.find((page) => page.slug === window.location.pathname);

  switch (window.location.pathname) {
    case `/products/${id}`:
      return (
        <Helmet>
          <title>{seo?.title || "Niagara CounterTops"}</title>
          {seo?.description && <meta name="description" content={seo?.description} />}

          <link href="../css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="../css/webflow.css" rel="stylesheet" type="text/css" />
          <link href="../css/niagara-counter-tops.webflow.css" rel="stylesheet" type="text/css" />
        </Helmet>
      );

    default:
      return (
        <Helmet>
          <title>{seo?.title || "Niagara CounterTops"}</title>
          {seo?.description && <meta name="description" content={seo?.description} />}
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link href="css/niagara-counter-tops.webflow.css" rel="stylesheet" type="text/css" />
        </Helmet>
      );
  }
}

export default Head;
