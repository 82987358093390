import React, { useEffect, useState } from "react";
import "../css/MarketingBanner.css";
import { useSelector } from "react-redux";
import RichText from "./RichText";

const ttl = 10 * 60 * 1000; // 10 minutes in milliseconds

const MarketingBanner = () => {
  const [isOpen, setIsOpen] = useState(false);
  // access our data so that we can use it
  const { data } = useSelector((state) => state.cms);

  const singleTypeSlug = "navbar";
  const contentSlug = "pop-up";
  const navData = data[singleTypeSlug] && data[singleTypeSlug].attributes;
  const content = data[contentSlug] && data[contentSlug].attributes;

  //   open after 2 seconds of page load
  useEffect(() => {
    document.addEventListener("keydown", closeBanner, false);
    if (!isBannerViewed()) {
      openBanner();
    }

    return () => {
      document.removeEventListener("keydown", closeBanner, false);
    };
  }, []);

  const isBannerViewed = () => {
    const bannerTimestamp = localStorage.getItem("nct_banner");
    if (!bannerTimestamp) return false;

    const currentTime = new Date().getTime();
    const bannerTime = parseInt(bannerTimestamp, 10);

    return currentTime - bannerTime < ttl;
  };

  const openBanner = () => {
    setTimeout(() => {
      setIsOpen(true);
      localStorage.setItem("nct_banner", new Date().getTime().toString());
    }, 2000);
  };

  const closeBanner = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  const renderContent = () => {
    if (isOpen)
      return (
        <>
          <img src={navData?.logo?.data?.attributes?.url} loading="lazy" alt="" className="logoicon" />
          {content?.heading && <h1 style={{ color: "#f06b24", textAlign: "center" }}>{content.heading}</h1>}
          {content?.body && <RichText text={content.body} left={false} />}

          {content?.ctaPreHeading && (
            <h4 style={{ textAlign: "center", marginTop: "40px", marginBottom: 0 }}>
              {content.ctaPreHeading}
              <br />
            </h4>
          )}
          {content?.ctaHeading && <h2 style={{ color: "#22223b", textAlign: "center" }}>{content.ctaHeading}</h2>}
        </>
      );

    return <span>{content?.buttonText || "SALE!"}</span>;
  };

  if (!content) return null;
  return (
    <div onClick={() => setIsOpen(!isOpen)} className={`marketing-banner ${isOpen ? "open" : "closed"}`}>
      <div onClick={(e) => isOpen && e.stopPropagation()} className="inner">
        {renderContent()}
      </div>
      <img src="/images/x.png" alt="close" className="closeIcon" onClick={closeBanner} />
    </div>
  );
};

export default MarketingBanner;
