// Our Navbar component
// This will need to be modified to match the JSX coming from webflow

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";

function Navbar({ Banner = () => <></> }) {
  // access our data so that we can use it
  const { data } = useSelector((state) => state.cms);
  const { direction } = useSelector((state) => state.nav);

  const singleTypeSlug = "navbar";
  const navData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!navData) return null;

  return (
    <div
      data-animation="default"
      data-collapse="small"
      data-duration={400}
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className={`navbar w-nav ${direction === "down" && "now--color shadow"}`}
    >
      {<Banner />}
      <div className="navcontainer w-container">
        <Link to="/" aria-current="page" className="link-block w-inline-block w--current">
          <img src={navData?.logo?.data?.attributes?.url} loading="lazy" alt="" className="logoicon" />
        </Link>
        <nav role="navigation" className="navmenu w-nav-menu">
          <div className="div-block" />
          {navData?.navLinks?.map((item, i) => {
            return (
              <div key={uuid()} className="navLinkContainer">
                <Link to={item.link} className={i === navData.navLinks.length - 1 ? "navlink cta w-nav-link" : "navlink w-nav-link"}>
                  {item.text}
                </Link>
                {item.subLinks?.length > 0 && (
                  <div className="navLinkDropdown">
                    {item.subLinks?.map((subItem) => {
                      return (
                        <Link key={uuid()} to={subItem.slug} className="navlink w-nav-link">
                          {subItem.text}
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </nav>
        <div className="div-block-2">
          <div className="menu-button w-nav-button">
            <div data-w-id="146312af-5677-fe91-e4fb-8c3a7c65cb88" className="nav-button-animated w-nav-button">
              <div data-w-id="146312af-5677-fe91-e4fb-8c3a7c65cb89" className="top-line gradient" />
              <div data-w-id="146312af-5677-fe91-e4fb-8c3a7c65cb8a" className="middle-line gradient" />
              <div data-w-id="146312af-5677-fe91-e4fb-8c3a7c65cb8b" className="bottom-line gradient" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
