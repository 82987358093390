import React from "react";
import { Link } from "react-router-dom";
import Page from "../components/Page";

function ErrorPage() {
  return (
    <Page>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh" }}>
        <h1>Oops Something Went Wrong!</h1>
        <div>
          <Link to="/" className="button shadow w-button">
            Go To Home Page
          </Link>
        </div>
      </div>
    </Page>
  );
}

export default ErrorPage;
