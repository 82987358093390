import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import NavSwitch from "../components/NavSwitch";
import Page from "../components/Page";
import LoadingPage from "./LoadingPage";
import { v4 as uuid } from "uuid";
import RichText from "../components/RichText";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import Table from "../components/Table";
import { Link } from "react-router-dom";
import ProductCards from "../components/ProductCards";

const ProductsPage = () => {
  // access our data so that we can display it
  const singleTypeSlug = "products-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;

  return (
    <Page data-wf-page="63a4aec8c97e6a6285206a0e" data-wf-site="63a4ab5fc97e6aeff12033c5">
      <div
        className="hero-subpage products wf-section"
        style={{
          backgroundPosition: "0px 0px, 50% 50%",
          backgroundImage: `radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, 0.6), transparent), url('${cmsData?.heroBackground?.data?.attributes?.url}')`,
        }}
      >
        <div className="container-2 w-container">
          <img src={cmsData?.logo?.data?.attributes?.url} loading="lazy" alt="" className="mainlogo subpage" />
          {cmsData?.tagline?.split("\n")?.map((el) => {
            return (
              <div key={uuid()} className="tagline">
                <strong className="taglinetext">{el}</strong>
              </div>
            );
          })}
        </div>
      </div>
      <NavSwitch />
      <div className="textblock subpage wf-section">
        <div className="w-container">
          <RichText text={cmsData?.textBlock1} />
        </div>
      </div>
      <ProductCards products={cmsData?.products} />
      <div className="section-5 wf-section">
        <div className="w-container">
          <Table data={cmsData?.table?.table?.data?.attributes} />
        </div>
      </div>

      {/* process */}
      {/* <div className="wf-section centered">{cmsData?.featuresImg && <img src={cmsData?.featuresImg?.data?.attributes?.url} loading="lazy" alt="" className="processimg" />}</div> */}
    </Page>
  );
};

export default ProductsPage;
