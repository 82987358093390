import { WAYPOINT } from "../actions/nav";

const initialState = {
  direction: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WAYPOINT:
      return {
        direction: action.direction,
      };

    default:
      return state;
  }
};
