import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import NavSwitch from "../components/NavSwitch";
import Page from "../components/Page";
import LoadingPage from "./LoadingPage";
import { v4 as uuid } from "uuid";
import RichText from "../components/RichText";
import Maps from "../components/Maps";

const DealerPage = () => {
  // access our data so that we can display it
  const singleTypeSlug = "dealers-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // logic for carousel
  const [carouselIndex, setCarouselIndex] = useState(0);

  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;
  console.log(cmsData);

  return (
    <Page data-wf-page="63a4aec8c97e6a6285206a0e" data-wf-site="63a4ab5fc97e6aeff12033c5">
      <div
        className="hero-subpage products wf-section"
        style={{
          backgroundPosition: "0px 0px, 50% 50%",
          backgroundImage: `radial-gradient(circle farthest-corner at 50% 50%, rgba(0, 0, 0, 0.6), transparent), url('${cmsData?.heroBackground?.data?.attributes?.url}')`,
        }}
      >
        <div className="container-2 w-container">
          <img src={cmsData?.logo?.data?.attributes?.url} loading="lazy" alt="" className="mainlogo subpage" />
          {cmsData?.tagline?.split("\n")?.map((el) => {
            return (
              <div key={uuid()} className="tagline">
                <strong className="taglinetext">{el}</strong>
              </div>
            );
          })}
        </div>
      </div>
      <NavSwitch />
      <div className="textblock subpage wf-section">
        <div className="w-container">
          <RichText text={cmsData?.textBlock1} />
        </div>
      </div>

      {/* subnav */}
      <div className="stickysubnav wf-section">
        <div className="container-5 w-container">
          {cmsData?.dealers?.map((el, i) => {
            // let item = el?.location?.data?.attributes;
            return (
              <Fragment key={uuid()}>
                <a
                  onClick={() => setCarouselIndex(i)}
                  className="onpagelink"
                  style={{
                    opacity: carouselIndex === i ? 1 : 0.6,
                  }}
                >
                  {/* href={`#${item?.title}`} */}
                  {/* {item?.title} */}
                  {el.navLink}
                </a>
                {i + 1 !== cmsData?.dealers?.length && <div className="bluedot" />}
              </Fragment>
            );
          })}
        </div>
      </div>

      {/* maps section */}
      <div className="section-4 wf-section">
        <div className="container-6 w-container">
          <Maps data={cmsData?.dealers} selectedItem={carouselIndex} onChange={setCarouselIndex} autoplay={false} />
        </div>
      </div>
    </Page>
  );
};

export default DealerPage;
